
import { Component, Vue, PropSync } from 'vue-property-decorator'

@Component
export default class About extends Vue {
    @PropSync('show', { type: Boolean }) showDialog!: boolean

    loadBenchy(): void {
        this.$eventHub.$emit('LoadBenchy')
        this.showDialog = false
    }
}
