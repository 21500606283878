import { render, staticRenderFns } from "./RenderQuality.vue?vue&type=template&id=152f4d62&scoped=true&"
import script from "./RenderQuality.vue?vue&type=script&lang=ts&"
export * from "./RenderQuality.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152f4d62",
  null
  
)

export default component.exports