
import ToolsDialog from '@/components/tools/ToolsDialog.vue'
import ViewerMixin from '../mixin/ViewerMixin'
import { Component, Ref, Mixins, Watch } from 'vue-property-decorator'
import ToolbarItem from './ToolbarItem.vue'
import ToolbarPopover from './ToolbarPopover.vue'
import RenderQuality from '@/components/quality/RenderQuality.vue'
import RenderMode from '@/components/quality/RenderMode.vue'
import RenderColors from '@/components/quality/RenderColors.vue'
import CustomActions from '@/components/custom/CustomActions.vue'

@Component({
    components: {
        ToolsDialog,
        ToolbarItem,
        ToolbarPopover,
        RenderQuality,
        RenderMode,
        RenderColors,
        CustomActions
    }
})
export default class Toolbar extends Mixins(ViewerMixin) {
    showToolsDialog = false
    @Ref('fileInput') fileInput!: HTMLInputElement
    selectedButton = []

    showFileSelect(): void {
        this.fileInput.click()
    }
    fileSelected(e: any): void {
        this.$eventHub.$emit('openLocalFile', e.target.files[0])
    }

    liveTrack(): void {
        this.$eventHub.$emit('trackCurrentJob')
    }

    get reloadRequiredColor(): string {
        return this.$store.state.viewer.reloadRequired ? 'green' : ''
    }

    get qualityColor(): string {
        return this.renderQuality < 2 ? 'red' : ''
    }

    get qualityIcon(): string {
        if (this.renderQuality < 2) return 'mdi-speedometer-slow'
        if (this.renderQuality < 5) return 'mdi-speedometer-medium'
        return 'mdi-speedometer'
    }

    @Watch('selectedButton') selectedButtonUpdated(): void {
        if (this.selectedButton.length > 0) {
            this.selectedButton.pop()
        }
    }

    get canLiveTrack(): boolean {
        return !this.$store.state.viewer.liveTracking && this.$store.getters['printer/isPrinting'] && !this.showProgress
    }
}
