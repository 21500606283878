
import { Component, PropSync, Watch, Mixins } from 'vue-property-decorator'
import ViewerMixin from '../mixin/ViewerMixin'
import ToolCard from './ToolCard.vue'
import { Tool } from '@/store/viewer/types'
import { getDefaultTools, resetTools } from '@/store/viewer'
import { TranslateResult } from 'vue-i18n'

@Component({
    components: {
        ToolCard
    }
})
export default class ToolsDialog extends Mixins(ViewerMixin) {
    @PropSync('show', { type: Boolean }) private showDialog!: boolean
    editTools: Tool[] = []
    toolQty = 1
    toolNumbers = [...Array(10).keys()].map((i) => i + 1)

    reset(): void {
        const defaultTools = getDefaultTools()
        //Deep copy
        this.editTools.splice(0, this.editTools.length)
        this.editTools.push(...JSON.parse(JSON.stringify(defaultTools)))
        this.editTools.forEach((t: Tool) => (t.id = Math.random()))
        this.toolQty = this.editTools.length
    }

    mounted(): void {
        this.reset()
    }

    save(): void {
        this.$store.commit('viewer/saveTools', this.editTools)
        if (this.currentFileName) {
            this.reloadRequired = false
        }
        this.showDialog = false
        this.updateTools()
        this.viewer().gcodeProcessor.forceRedraw()
    }

    cancel(): void {
        this.showDialog = false
        this.reset()
    }

    numberRule(v: any): boolean | string | TranslateResult {
        const val = parseInt(v)
        if (!isNaN(val) && val >= 1 && val <= 10) return true
        return this.$t('viewer.tools.qtyerror')
    }

    loadDefault(): void {
        //Deep copy
        this.editTools.splice(0, this.editTools.length)
        this.editTools.push(...resetTools())
        this.editTools.forEach((t: Tool) => (t.id = Math.random()))
        this.toolQty = this.editTools.length
    }

    @Watch('toolQty')
    toolQtyUpdated(to: number): void {
        if (this.numberRule(to) !== true) return

        if (to > this.editTools.length) {
            const count = to - this.editTools.length - 1

            for (let idx = 0; idx <= count; idx++) {
                this.editTools.push(new Tool())
            }
        } else {
            const count = this.editTools.length - to - 1
            for (let idx = 0; idx <= count; idx++) {
                this.editTools.pop()
            }
        }
    }
}
