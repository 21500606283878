
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ToolbarPopover extends Vue {
    menu = false
    @Prop() icon!: string
    @Prop() title!: string
    @Prop() color!: string

    get size(): any {
        const size = { xs: '', sm: 'medium', md: '', lg: 'large', xl: 'x-large' }[this.$vuetify.breakpoint.name]
        return size ? { [size]: true } : {}
    }
}
