
import Vue from 'vue'
import { Component, PropSync } from 'vue-property-decorator'
import DuetPollConnector from '@/store/connectors/DuetPollConnector'
import DuetRestConnector from '@/store/connectors/DuetRestConnector'
import KlipperRestConnector from '@/store/connectors/KlipperRestConnector'
import OctoPrintConnector from '@/store/connectors/OctoPrintConnector'
import BaseConnector from '@/store/connectors/BaseConnector'
import { ConnectionType } from '@/store/connectors/types'

@Component
export default class ConnectDialog extends Vue {
    @PropSync('show', { type: Boolean }) private showDialog!: boolean
    address = ''
    connector!: BaseConnector
    connectionType: ConnectionType = ConnectionType.rrf
    password = ''
    error = ''
    busy = false

    mounted(): void {
        try {
            const lastConnection = this.$store.getters['connections/getLastConnection']
            if (lastConnection) {
                this.address = lastConnection.address
                this.connectionType = lastConnection.connectionType
            }
        } catch (ex) {
            //Do nothing
        }
    }

    get isConnected(): boolean {
        return this.$store.getters['connections/isConnected']
    }

    get currentConnection(): BaseConnector {
        return this.$store.state.connection
    }

    async connect(): Promise<void> {
        if (this.isConnected) return

        if (this.busy) return

        try {
            this.busy = true

            switch (this.connectionType) {
                case 0:
                    //Try poll connection
                    try {
                        this.connector = new DuetPollConnector(this.$store)
                        await this.connector.connect(this.address, this.password)
                    } catch {
                        //do nothing
                    }

                    //try rest connector
                    try {
                        if (!this.connector.connected) {
                            this.connector = new DuetRestConnector(this.$store)
                            await this.connector.connect(this.address)
                        }
                    } catch {
                        //do nothing
                    }

                    break
                case 1:
                    try {
                        //try poll connector
                        this.connector = new KlipperRestConnector(this.$store)
                        await this.connector.connect(this.address, this.password)
                    } catch {
                        //do nothing
                    }
                    break
                case 2:
                    try {
                        if (this.password === '' || this.password === 'ENTER API KEY') {
                            this.password = 'ENTER API KEY'
                            this.busy = false
                            return
                        }
                        this.connector = new OctoPrintConnector(this.$store)
                        await this.connector.connect(this.address, this.password)
                    } catch {
                        //do nothing
                    }
                    break
            }

            this.busy = false
            if (!this.connector.connected) {
                this.error = `Unable to connect to ${this.address}`
                return
            }
            this.$store.commit('connections/setConnection', this.connector)
            this.showDialog = false
        } finally {
            this.busy = false
        }
    }
    cancel(): void {
        this.showDialog = false
    }
}
