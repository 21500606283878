
import { Component, Mixins } from 'vue-property-decorator'
import ViewerMixin from '@/components/mixin/ViewerMixin'
import ColorPicker from '@/components/tools/ColorPicker.vue'

@Component({ components: { ColorPicker } })
export default class Mode extends Mixins(ViewerMixin) {
    items = [
        { name: 'Color', value: 0 },
        { name: 'Feed Rate', value: 1 },
        { name: 'Feature', value: 2 }
    ]
}
