
'use strict'

import Vue from 'vue'
import { Component, ModelSync, Watch } from 'vue-property-decorator'

@Component
export default class ColorPicker extends Vue {
    @ModelSync('editorcolor', 'change') editcolormodel!: string
    internalTextColor = '#000000'
    color = '#000000'
    menu = false

    get backgroundColorStyle(): any {
        return { minWidth: '220px', backgroundColor: this.color }
    }

    get swatchStyle(): any {
        const { color, menu } = this
        return {
            backgroundColor: color,
            cursor: 'pointer',
            height: '30px',
            width: '30px',
            borderRadius: menu ? '50%' : '4px',
            transition: 'border-radius 200ms ease-in-out'
        }
    }
    mounted(): void {
        this.color = this.editcolormodel
        this.internalTextColor = this.editcolormodel
    }

    updateValue(val: string): void {
        this.color = val
        if (!val.startsWith('#')) {
            this.color = '#' + val
        }
        this.color = this.color.toUpperCase().padEnd(7, '0').substring(0, 7)
        this.internalTextColor = this.color
        this.$emit('change', this.color)
    }

    @Watch('editcolor') editColorChanged(to: string): void {
        this.color = to
        this.internalTextColor = to
    }
}
