
import { Component, Mixins } from 'vue-property-decorator'
import ViewerMixin from '../mixin/ViewerMixin'
import ColorPicker from '@/components/tools/ColorPicker.vue'

@Component({
    components: {
        ColorPicker
    }
})
export default class RenderColors extends Mixins(ViewerMixin) {}
