
import BaseMixin from '@/components/mixin/BaseMixin'
import { Component, Mixins } from 'vue-property-decorator'
import { ConnectionType } from '@/store/connectors/types'

@Component
export default class Status extends Mixins(BaseMixin) {
    get getConnectionIcon(): string {
        try {
            switch (this.$store.state.connections.connection?.connectionType) {
                case ConnectionType.rrf:
                    return require('@/assets/duet.png')

                case ConnectionType.klipper:
                    return require('@/assets/klipper.png')
                case ConnectionType.octoprint:
                    return require('@/assets/octoprint.png')
            }
        } catch {
            //do nothing
        }
        return ''
    }

    get getLabelStyle() {
        return this.isMobile ? 'address-text-sm' : 'address-text'
    }

    get address(): string {
        return this.$store.state.connections.connection?.address ?? ''
    }

    get status(): string {
        if (this.address !== '') {
            return ` -- ${this.$store.state.printer.status}`
        }
        return ''
    }
}
