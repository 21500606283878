
import { Component, Mixins } from 'vue-property-decorator'
import ViewerMixin from '@/components/mixin/ViewerMixin'

@Component
export default class RenderQuality extends Mixins(ViewerMixin) {
    items = [
        { name: 'Low', value: 1 },
        { name: 'Medium', value: 3 },
        { name: 'High', value: 5 },
        { name: 'Max', value: 6 }
    ]

    get renderQualityWarning(): string {
        if (this.renderQuality === 6) {
            return 'Max quality ignores Force Line Mode '
        }
        return ''
    }
}
