
import '@/assets/styles/page.scss'
import { Component, Mixins } from 'vue-property-decorator'
import Viewer from '@/components/ViewerUI/Viewer.vue'
import Connect from '@/components/ViewerUI/Connect.vue'
import Status from '@/components/utils/Status.vue'
import Toolbar from '@/components/Toolbars/Toolbar.vue'
import BaseConnector from './store/connectors/BaseConnector'
import About from '@/components/about/About.vue'
import ChromeInfo from '@/components/chromeinfo/ChromeInfo.vue'

import { PrinterStateMotion, PrinterStatus } from './store/printer/types'
import BaseMixin from './components/mixin/BaseMixin'

@Component({
    components: {
        Viewer,
        Connect,
        Status,
        Toolbar,
        About,
        ChromeInfo
    }
})
export default class App extends Mixins(BaseMixin) {
    showConnectionDialog = false
    showToolsDialog = false
    showAboutDialog = false
    showChromeInfo = false

    get connection(): BaseConnector {
        return this.$store.state.connections.connection
    }

    get motion(): PrinterStateMotion {
        return this.$store.state.printer.motion
    }

    get status(): PrinterStatus {
        return this.$store.state.printer.status
    }

    get isConnected(): boolean {
        return this.$store.getters['connections/isConnected']
    }

    get toolbarClass(): string {
        return this.isMobile ? 'toolbar-sm' : 'toolbar'
    }

    async downloadTest(): Promise<void> {
        this.$eventHub.$emit('trackCurrentJob')
    }

    dumpState(): void {
        console.log(this.$store.state)
    }

    disconnect(): void {
        this.connection.disconnect()
        this.$eventHub.$emit('disconnect')
    }
}
